<template>
  <div v-if="billing_account">
    <b-tabs lazy>
      <b-tab title="Service Order">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('ServiceOrder', 'BillTo', [billing_account])"
          :_index_params="$d.relationIndexParams('ServiceOrder', 'BillTo', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="ServiceOrder"
          title="Service Orders"
        ></DescriptorCard>
      </b-tab>
      <b-tab title="Service Order Document">
        <DescriptorCard
          :fixed_params="$d.relationFixedParams('ServiceOrderDocument', 'BillTo', [billing_account])"
          :_index_params="$d.relationIndexParams('ServiceOrderDocument', 'BillTo', billing_account.id)"
          :stateIdentifier="'_billing_account_' + billing_account.id"
          class_name="ServiceOrderDocument"
          title="Service Order Documents"
        ></DescriptorCard>
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <div class="alert alert-warning">Billing Account Required for Servicing</div>
  </div>
</template>

<script>
export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
  },
  props: {
    billing_account: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
